// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Lingui from "../../locales/Lingui.re.mjs";
import * as Localized from "../shared/i18n/Localized.re.mjs";
import * as LeaguePage from "../pages/LeaguePage.re.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";

var LoaderArgs = {};

var loadMessages = Lingui.loadMessages({
      ja: import("../../locales/src/components/pages/LeaguePage.re/ja"),
      en: import("../../locales/src/components/pages/LeaguePage.re/en")
    });

async function loader(param) {
  var params = param.params;
  if (import.meta.env.SSR) {
    await Localized.loadMessages(params.lang, loadMessages);
  }
  return {
          i18nLoaders: import.meta.env.SSR ? undefined : Caml_option.some(Localized.loadMessages(params.lang, loadMessages))
        };
}

var HydrateFallbackElement = JsxRuntime.jsx("div", {
      children: "Loading fallback..."
    });

var Component = LeaguePage.make;

export {
  Component ,
  LoaderArgs ,
  loadMessages ,
  loader ,
  HydrateFallbackElement ,
}
/* loadMessages Not a pure module */
